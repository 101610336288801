import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewContainerRef,
  inject,
  viewChild,
} from '@angular/core';
import {
  DynamicDialogRef,
  DialogService as PrimeDialogService,
} from 'primeng/dynamicdialog';
import { DialogService, DialogSize } from '../../../services/dialog.service';

@Component({
  selector: 'core-dialog-shell',

  imports: [CommonModule],
  templateUrl: './dialog-shell.component.html',
})
export class DialogShellComponent implements OnInit {
  // dialogTitle = '';
  dialogId = '';
  size: DialogSize = DialogSize.SMALL;
  defaultValue: unknown;
  header = '';

  container = viewChild('dialogContainer', { read: ViewContainerRef });

  dialogService = inject(DialogService);

  constructor(
    public ref: DynamicDialogRef,
    private primeDialogService: PrimeDialogService
  ) {
    const instance = this.primeDialogService.getInstance(this.ref);
    this.dialogId = instance?.data?.dialogId;
    this.size = instance?.data?.size || DialogSize.SMALL;
    this.header = instance?.data?.header || '';
    this.defaultValue = this.dialogService.getDialogRef(
      this.dialogId
    )?.params?.defaultValue;
  }

  ngOnInit() {
    this.createContent();
  }

  closeDialog() {
    this.ref.close();
  }

  private createContent() {
    this.container()?.clear();

    const component = this.dialogService.getDialogRef(this.dialogId)?.component;

    if (component) {
      const ref = this.container()?.createComponent(component);
      const params = this.dialogService.getDialogRef(this.dialogId)?.params;
      if (ref && params) {
        Object.keys(params).forEach((key) => {
          ref.setInput(key, params[key]);
        });
      }
    }
  }
}
