import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import {
  DynamicDialogRef,
  DialogService as PrimeDialogService,
} from 'primeng/dynamicdialog';
import { ButtonComponent } from '../../button/button.component';
import { LabelComponent } from '../../label/label.component';

@Component({
  selector: 'core-confirm-dialog-shell',

  imports: [CommonModule, ButtonComponent, LabelComponent],
  templateUrl: './confirm-dialog-shell.component.html',
  styleUrl: './confirm-dialog-shell.component.css',
})
export class ConfirmDialogShellComponent {
  message = signal('');
  confirmText = signal('Yes');
  cancelText = signal('No');
  header = signal('Confirm');

  constructor(
    public ref: DynamicDialogRef,
    private primeDialogService: PrimeDialogService
  ) {
    const instance = this.primeDialogService.getInstance(this.ref);
    this.message.set(instance?.data?.message || '');
    this.confirmText.set(instance?.data?.confirmText || 'Yes');
    this.cancelText.set(instance?.data?.cancelText || 'No');
    this.header.set(instance?.data?.header || 'Confirm');
  }

  onConfirm() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }
}
