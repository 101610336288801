import { Injectable, Type, inject } from '@angular/core';
import {
  DynamicDialogRef,
  DialogService as PrimeDialogService,
} from 'primeng/dynamicdialog';
import { Observable, take, tap } from 'rxjs';
import { ConfirmDialogShellComponent } from '../components/shell/confirm-dialog-shell/confirm-dialog-shell.component';
import { DialogShellComponent } from '../components/shell/dialog-shell/dialog-shell.component';
import { autoSubscribe } from '../type';

export enum DialogSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA = 'xl',
}
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  ds = inject(PrimeDialogService);

  private currentDialogRef: {
    ref: DynamicDialogRef;
    id?: string;
    component?: Type<unknown>;
    layoutConfigPath?: string;
    params?: any;
  }[] = [];

  @autoSubscribe()
  openDialog<T>(
    component: Type<T>,
    params?: { header?: string; data?: unknown; size?: DialogSize },
    dialogId?: string
  ): Observable<any> {
    const ref = this.ds.open(DialogShellComponent, {
      header: params?.header || '',
      data: {
        dialogId,
        size: params?.size || DialogSize.SMALL,
        header: params?.header || '',
      },
    });

    if (this.currentDialogRef.some((i) => i.id === dialogId)) {
      console.log('Dialog with id ' + dialogId + ' is already opened');
    }

    this.currentDialogRef.push({
      id: dialogId,
      ref,
      component,
      params: params?.data,
    });

    return ref.onClose.pipe(
      take(1),
      tap(() => {
        this.removeDialogRef(dialogId);
      })
    );
  }

  private removeDialogRef(dialogId?: string) {
    this.currentDialogRef = dialogId
      ? this.currentDialogRef.filter((i) => i.id !== dialogId)
      : this.currentDialogRef.slice(0, -1);

    if (this.currentDialogRef.length === 0) {
      document.getElementsByTagName('p-dynamicdialog')[0].remove();
      document.body.classList.remove('p-overflow-hidden');
    }
  }

  closeModal(params?: any, dialogId?: string) {
    const dialogRef = dialogId
      ? this.currentDialogRef.find((i) => i.id === dialogId)
      : this.currentDialogRef[this.currentDialogRef.length - 1];

    if (dialogRef) {
      dialogRef.ref.close.call(dialogRef.ref, params);
    }
  }

  closeAllDialogs() {
    this.currentDialogRef.forEach((i) => i.ref.close());
    this.currentDialogRef = [];
  }

  getDialogRef(dialogId: string) {
    return this.currentDialogRef.find((i) => i.id === dialogId);
  }

  openConfirmDialog(message: string): Observable<boolean> {
    return this.ds
      .open(ConfirmDialogShellComponent, {
        data: { message, size: DialogSize.SMALL },
        closable: false,
      })
      .onClose.pipe(take(1));
  }
}
